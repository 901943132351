<template>
    <div style="width: 97%; margin-left:3%;float: left; text-align: left">
        <div style="width: 100%; ;margin-bottom:20px;float: left;text-align:left; font-size:1.0125rem;margin-top: 30px">You application is under voting.
        </div>
        <div style="width: 100%;float:left;">
            <div style="font-weight: 600; font-size: 1.1rem; display: inline">
                Project:
            </div>
            <span class="CWords" style="display: inline">{{projectName}}</span>
        </div>
        <div style="width: 100%;float:left;margin-top: 10px">
            <div>
                <div class="introBlock1" >
                    <div style="font-weight: 600; font-size: 1.125rem;width: 70%;float: left">
                        Abstract:
                    </div>
                    <div style="width: 80%;float: left;padding:10px 3%;margin-top:1rem;margin-bottom: 15px">
                        {{abstract}}
                    </div>
                    <Card style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                        <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                            <Icon type="ios-leaf" /> {{form.abs_type}}</div>
                        <div style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.abs_content}}</div>
                    </Card>
                </div>
                <div class="introBlock1">
                    <div style="width: 100%;float: left;margin-bottom: 15px">
                        <div style="font-weight: 600; font-size: 1.125rem;width: 70%;float: left">
                            Environment:
                        </div>
                        <div style="width: 80%;float: left;padding:10px 3%;margin-top:1rem;margin-bottom: 15px">
                            {{abstract}}
                        </div>
                        <Card style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                            <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                                <Icon type="ios-leaf" /> {{form.env_type}}</div>
                            <div style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.abs_content}}</div>
                        </Card>
                    </div>


                </div>
                <div class="introBlock1">
                    <div style="width: 100%;float: left;margin-bottom: 15px">
                        <div style="font-weight: 600; font-size: 1.125rem;width: 70%;float: left">
                            Functions: </div>
                        <div style="width: 30%;float: left">
                        </div>
                    </div>
                    <Card style="padding:0px 5%;width: 80%;float: left;margin-bottom: 15px">
                        <div style="width: 100%;text-align: left;float: left;color: darkgray;font-size: 1.045rem;margin-bottom:10px;font-weight: 500">
                            <Icon type="ios-leaf" /> {{form.abs_type}}</div>
                        <div style="width: 100%;margin-top:15px;margin-bottom: 10px">{{form.abs_content}}</div>
                    </Card>
                    <div style="text-align: left;width: 100%;float: left">
                        <div style="margin: 1rem 1rem; padding: 1rem 1rem; border: #000000 solid 0.0625rem; width: fit-content;">
                            <img src="../assets/func.jpg"/>
                        </div>
                        <Table :columns="funcCol" :data="funcData"
                               style="width: 100%; margin-bottom: 2rem;"></Table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "createJoin3",
        data() {
            return {
                projectName: 'projectName',
                userName: 'userName',
                abstract:'The Visual Understanding Environment (VUE) project at Tufts\' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. VUE provides a visual environment for structuring, presenting, and sharing digital information and an OKI-compliant software bridge for connecting to FEDORA-based digital repositories. Using VUE\'s concept mapping interface, faculty and students design customized semantic networks of digital resources drawing from digital libraries, local files and the Web.',
                environment:'this project don\'t need any environment',
                form:{
                    abs_type:'Modify',
                   abs_content:' The Visual Understanding Environment (VUE) project at Tufts\' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. ',
                    env_type:'For more information',
                    env_content:'',
                    func_type:'',
                    func_content:'',
                    func_picture:'',
                },
                funcCol: [
                    {
                        title: 'Function Name',
                        key: 'name',
                        width: 250
                    },
                    {
                        title: 'Description',
                        key: 'description'
                    }
                ],
                funcData:[
                    {
                        name: 'RL-Adventure',
                        description: `This is easy-to-follow step-by-step Deep Q Learning tutorial with clean readable code.`,
                        date: '2016-10-03',
                        lines: '38431'
                    },
                    {
                        name: 'Neural Episodic Control',
                        description: `First, I recommend to use small test problems to run experiments quickly. Then, you can continue on environments with large observation space.`,
                        date: '2018-10-03',
                        lines: '20572'
                    },
                    {
                        name: 'Rainbow',
                        description: `Combining Improvements in Deep Reinforcement Learning`,
                        date: '2020-10-03',
                        lines: '16390'
                    },
                    {
                        name: 'Noisy Networks for Exploration',
                        description: `Carefully go through the paper. Try to see what is the problem the authors are solving. `,
                        date: '2019-10-03',
                        lines: '7989'
                    }
                ],

            }
        }
    }
</script>

<style scoped>

</style>
